export interface IPaymentList {
    pagination: {
        totals: number;
        pages: number;
    };
    data: Array<{
        _id:         string;
        amount:      number;
        discount:    number;
        type:        string;
        reference:   string;
        expiredDate: Date;
        status:      string;
        shopId:      string;
        createdAt:   Date;
        updatedAt:   Date;
        endDate:     Date;
        startDate:   Date;
        customField: CustomField;
    }>
}

export interface CustomField {
    outTradeNo:   string;
    tradeNo:      string;
    qrRawData:    string;
    qrExpireTime: Date;
}

export enum TRANSACTION_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
    SETTLED = 'SETTLED',
  }