import { useUserStore } from '@/pinia/user/user.pinia'
import { RouteLocationNormalized } from 'vue-router'

export const permission = (to: RouteLocationNormalized, from: RouteLocationNormalized): any => {
	//
	const userStore = useUserStore()
    
	if (to.fullPath.includes('/shop/detail')) {
		if (!userStore.getSettingShopPermission?.view) {
			return '/404'
		}
	}

}