import { defineStore } from 'pinia'
import { State } from '@/pinia/shipping/types'
import { IOrderPrintList } from '@/type/print'
export const useShippingStore = defineStore('shipping', {
	state: (): State => ({
		orderList: [],
		orderListID: [],
		paymentReceiptDate: '',
	}),
	getters: {
		getterOrderList(state: State) {
			return state.orderList
		},
		getterOrderListId(state: State) {
			return state.orderListID
		},
		getterPaymentReceiptDate(state: State) {
			return state.paymentReceiptDate
		},
	},
	actions: {
		// old mutations
		updateOrderList(orderList: Array<IOrderPrintList>) {
			this.orderList = orderList
		},
		updateOrderListId(orderListID: string[]) {
			this.orderListID = orderListID
		},
		updatePaymentReceiptDate(paymentReceiptDate: string) {
			this.paymentReceiptDate = paymentReceiptDate
		},
	},
})
