import { defineStore } from 'pinia'
import { fetchTopUpTransactionList, fetchTopUpAmountList, fetchPaymentQR } from '@/api/top-up/top-up.api'
import { ITopUpQR } from '@/pinia/top-up/type'
import { TRANSACTION_STATUS } from '../payment/type'
import { getTransactionHistory } from '@/api/report/report.api'
import { CREDIT_TRANSACTION_TYPE } from '@/constants/credit'
import { useCourierStore } from '../courier/courier.pinia'
import { useShopStore } from '../shop/shop.pinia'

export const topUpStore = defineStore('top-up', {
	state: () => ({
		isLoadingList: false,
		topUpHistory: [] as any,
		pagination: {
			offset: 1,
			limit: 10,
			pages: 0,
			totals: 0,
		},
		topUpOrderDetail: [] as any,
		isShowTopUpDialog: false,
		isLoadingAmount: false,
		isShowSubHistory: false,
		amountList: [] as any,
		isLoadingQR: false,
		isSubOrderDetail: false,
		paymentTopUp: {} as ITopUpQR,
		initialTransactionStatus: TRANSACTION_STATUS.PENDING,
		isShowDialogStatus: false,
		creditTransactionType: null as CREDIT_TRANSACTION_TYPE | null,
		transactionHistory: [] as any,
		paginationTransaction: {
			offset: 1,
			limit: 10,
			pages: 0,
			totals: 0,
		},
		isLoadingTransaction: false,

	}),
	actions: {
		async getTopUpTransactionList(shopId: string, search?: string) {
			try {
				this.isLoadingList = true
				const options :any = {
					shopId,
					offset: this.pagination.offset,
					limit: this.pagination.limit,
					search,
				}
				const { data } = await fetchTopUpTransactionList(options)
				this.topUpHistory = data.data
				this.pagination.pages = data.pagination.pages
				this.pagination.totals = data.pagination.totals
				this.isLoadingTransaction = false
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoadingList = false
			}
		},
		async getTopUpAmountList() {
			const shopStore = useShopStore()
			const shopId = shopStore.selectedShopId as string
			try {
				this.isLoadingAmount = true
				const { data } = await fetchTopUpAmountList(shopId)
				if(data) {
					this.amountList = data
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoadingAmount = false
			}
		},
		async getTopUpQR(amount: number | string, shopId: string) {
			try {
				this.isLoadingQR = true
				const { data } = await fetchPaymentQR(Number(amount), shopId)
				if(data) {
					this.paymentTopUp = data
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoadingQR = false
			}
		},
		clearAllQR() {
			this.paymentTopUp = {} as ITopUpQR
		},
		async getTransactionHistoryList(creditTransactionId: string,search: string) {
			try {
				const courierStore = useCourierStore()
				
				this.isLoadingTransaction = true
				const { data } = await getTransactionHistory({
					creditTransactionId,
					offset: this.paginationTransaction.offset,
					limit: this.paginationTransaction.limit,
					search,
				})
				this.creditTransactionType = data.creditTransactionType
				this.transactionHistory = courierStore.mapCourierWithOrder(data).data
				this.paginationTransaction.pages = data.pagination.pages
				this.paginationTransaction.totals = data.pagination.totals
				this.isLoadingTransaction = false
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoadingTransaction = false
			}
		},
	}
})
