
import { computed, defineComponent } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'DialogWarningKYC',
	setup() {
		const { mobileMode: isMobile } = useWindowSize()
		const router = useRouter()
		const userStore = useUserStore()
		const isWarning = computed(() => userStore.isShowDialogWarningKYC)

		const handleClose = () => {
		    userStore.updateIsShowDialogWarningKyc(false)
		}

		const goToKYC = () => {
			handleClose()
			router.push({ path: '/shop/kyc'})
		}

		return {
			handleClose,
			goToKYC,
			isWarning,
			isMobile,
		}
	},
})
