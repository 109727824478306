import { RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useMiddleAccount } from '@/use/middleAccount'

export const shopList: RouteRecordRaw = {
	path: '/shop',
	name: 'Shoplist',
	meta: { auth: true, title: 'ร้านค้า' },
	component: () => import('@/layout/LayoutBackDrop.vue'),
	children: [
		{
			path: '',
			name: 'selectShop',
			component: () => import('@/views/shop/index.vue'),
		},
	],
}

export const shopRoute: RouteRecordRaw = {
	path: '/shop',
	name: 'ShopAdd',
	meta: { auth: true, title: 'เพิ่มร้านค้า' },
	component: () => import('@/layout/shop/LayoutRegisterShop.vue'),
	children: [
		{
			path: 'add',
			name: 'addShop',
			component: () => import('@/views/shop/add.vue'),
		},
	],
}

export const shopEditRoute: RouteRecordRaw = {
	path: '/shop',
	name: 'EditShop',
	meta: { auth: true },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: 'detail',
			name: 'shopDetail',
			redirect: (to) => {
				const userStore = useUserStore()
				if (!userStore.isOwner) {
					return '/404'
				}
				return '/shop/detail/overview'
			},
			component: () => import('@/layout/shop/LayoutShopDetail.vue'),
			children: [
				{
					path: 'overview',
					name: 'shopOverView',
					component: () => import('@/views/shop/detail/overview.vue'),
				},
				{
					path: 'user',
					name: 'shopUser',
					component: () => import('@/views/shop/detail/user.vue'),
					beforeEnter: (): boolean => {
						const userStore = useUserStore()
						const userPermissionShop =
							userStore.getSettingShopPermission

						return userPermissionShop?.delete as boolean
					},
				},
				{
					path: 'editCommission',
					name: 'shopCommission',
					component: () => import('@/views/shop/detail/editCommission.vue'),
					beforeEnter: (): boolean | string => {
						const userStore = useUserStore()
						const { isDealerType } = useMiddleAccount()

						if (isDealerType.value) {
							return '/404'
						}
						
						const userIsOwner = userStore.isOwner
						const userIsAdmin = userStore.isAdmin

						return userIsOwner as boolean || userIsAdmin as boolean
					},
				},
				{
					path: 'address',
					name: 'shopAddress',
					component: () => import('@/views/shop/detail/address.vue'),
					beforeEnter: () => {
						const { isDealerType } = useMiddleAccount()
				
						if (isDealerType.value) {
							return '/404'
						}
					},
				},
				{
					path: 'bank',
					name: 'shopBank',
					component: () => import('@/views/shop/detail/bank.vue'),
					beforeEnter: () => {
						const { isDealerType } = useMiddleAccount()
				
						if (isDealerType.value) {
							return '/404'
						}
					},
				},
				{
					path: 'setting',
					name: 'setting',
					component: () => import('@/views/shop/detail/setting.vue'),
				},
				{
					path: 'notification',
					name: 'notification',
					component: () =>
						import('@/views/shop/detail/notification.vue'),
				},
				{
					path: 'payment-receipt',
					name: 'paymentReceipt',
					component: () =>
						import('@/views/shop/detail/PaymentReceipt.vue'),
					beforeEnter: () => {
						const { isDealerType } = useMiddleAccount()
				
						if (isDealerType.value) {
							return '/404'
						}
					},
				},
				{
					path: 'courier',
					name: 'courierSetting',
					component: () => import('@/views/shop/detail/middle-account/connectCourier.vue'),
				}
			],
		},
		{
			path: 'kyc',
			name: 'shopKyc',
			component: () => import('@/views/shop/kyc.vue'),
			beforeEnter: () => {
				const userStore = useUserStore()
				const userIsOwner = userStore.isOwner

				if (!userIsOwner) {
					return '/404'
				}
			},
		},
		{
			path: 'sms/history',
			name: 'smsHistory',
			component: () =>
				import('@/components/sms/SMSHistory.vue')
		}
	],
}
