import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const booking: RouteRecordRaw = {
	path: '/booking',
	name: 'booking',
	meta: { auth: true, title: 'เรียกรถ' },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'BookingList',
			component: () => import('@/views/booking/BookingList.vue')
		}
	]
}

export default booking