import { AxiosResponse } from 'axios'
import { ReferralPayload } from './user.type'
import axios from '../instance'

export const getSearchUser = async (search: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/user', { params: { search } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getUserRoles = async (): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/user/role')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getUserPermission = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/user/profile', {
			params: {
				shopId
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const addReferralCode = async (payload: ReferralPayload): Promise<AxiosResponse> => {
	//
	try {
		const response = await axios.put('/user/referral-code', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const generateInviteUserLink = async (shopId: string, roleId: string): Promise<any> => {
	const { data } = await axios.post('/invite-user/generate-link', { shopId, roleId })
	return data
}

export const inviteCurrentUserToShop = async (token: string, userId: string): Promise<any> => {
	const { data } = await axios.post('/invite-user', { token, userId })
	return data
}