import { getUserPermission } from '@/api/user/user.api'
import { Roles } from '@/constants/roles'
import {
	State,
	UserData,
	AccessToken,
	IGetBillPayment,
	PermissionI,
} from '@/pinia/user/types'
import { defineStore } from 'pinia'
import { useShopStore } from '../shop/shop.pinia'
import router from '@/router'
import { USER_TYPE_INBOX } from '@/constants/shop'
import Cookies from 'js-cookie'

export const useUserStore = defineStore('user', {
	persist: true,
	state: (): State => ({
		userData: null,
		accessToken: null,
		permission: null,
		isShowDialogWarningKYC: false,
	}),
	getters: {
		getSettingShopPermission(state: State): PermissionI | undefined {
			return state.permission?.role?.permissions?.find(
				(pm: any) => pm.menu === 'SHOP_SETTING'
			)
		},
		isOwner(state): boolean {
			return state.permission?.role.name === Roles.OWNER
		},
		isManager(state): boolean {
			return state.permission?.role.name === Roles.MANAGER
		},
		isAdmin(state): boolean {
			return state.permission?.role.name === Roles.ADMIN
		}
	},
	actions: {
		// old mutation
		updateUserData(payload: UserData) {
			this.userData = payload
		},
		updateProviderAccessToken(accessToken: AccessToken) {
			this.accessToken = accessToken
		},
		updatePermission(permission: any) {
			this.permission = permission
		},
		updateIsShowDialogWarningKyc(isShowDialogWarningKYC: boolean) {
			this.isShowDialogWarningKYC = isShowDialogWarningKYC
		},
		updateUserPayment(payload: any) {
			if (this.permission?.billPayment) {
				this.permission.billPayment = payload
			}
		},
		// actions
		async getUserRoleInShopId({ shopId }: { shopId: string }) {
			try {
				const { data: permission } = await getUserPermission(shopId)

				this.updatePermission(permission)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async displayInboxDialog() {
			const shopStore = useShopStore()
			const { selectedShopId: shopId } = shopStore
			const isShopOwner = this.permission?.role.name === Roles.OWNER

			// If need fetch permission without check dialog again
			// Default is false
			if (this.permission?.billPayment && isShopOwner) {
				const params: IGetBillPayment = {
					shopId: shopId as string,
					isShowDialog: true,
				}
				if (router.currentRoute.value.fullPath === '/suspend') {
					params.isShowDialog = false
				}
				return
			} else if (this.permission && this.permission?.inbox?.length > 0) {
				const inboxTypeImage = this.permission?.inbox.some((e: any) => {
					return e.type === USER_TYPE_INBOX.POPUP_IMAGE
				})
				if (inboxTypeImage) {
					const inboxImageDetail = this.permission?.inbox.filter(
						(e: any) => {
							return e.type === USER_TYPE_INBOX.POPUP_IMAGE
						}
					)
					const isImageSecession = Cookies.get(
						'op_inbox_image_succession}'
					)
					if (!isImageSecession) {
						// await dispatch(ShopActionTypes.UPDATE_INBOX_IMAGE, inboxImageDetail)
						await shopStore.setUpdateInboxImage(
							inboxImageDetail as any
						)
					}
					return
				}
			} else {
				Cookies.remove(`op_bill_payment_secession_${shopId}`)
			}
		},
	},
})
