import { RouteRecordRaw } from 'vue-router'

const dashboard: RouteRecordRaw = {
	path: '/dashboard',
	name: 'DashboardRoute',
	meta: { title: 'ภาพรวม' },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'DasboardMainRoute',
			component: () => import('@/views/dashboard.vue'),
		}
	]
}
export default dashboard