export const USER_TYPE_INBOX = {
	MESSAGE_INFO: 'MESSAGE_INFO',
	MESSAGE_WARNING: 'MESSAGE_WARNING',
	MESSAGE_BILL_PAYMENT: 'MESSAGE_BILL_PAYMENT',
	POPUP_IMAGE: 'POPUP_IMAGE',
}

export enum OTPErrorCode {
	OTP_PHONE_NO_INVALID = 'OTP_PHONE_NO_INVALID',
	OTP_INVALID = 'OTP_INVALID',
	OTP_REFERENCE_INVALID = 'OTP_REFERENCE_INVALID',
	OTP_EXPIRED = 'OTP_EXPIRED',
}

export const threeSouthernProvinceCode = [94, 95, 96]
