import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const packageList: RouteRecordRaw = {
	path: '/package',
	name: 'packageRoute',
	meta: { title: 'แพ็กเกจ' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const { isDealerType } = useMiddleAccount()
		
		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'packageMainRoute',
			component: () => import('@/views/package/index.vue'),
		}
	]
}
export default packageList