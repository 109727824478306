import { defineStore } from 'pinia'
import firebase from 'firebase/app'
import { useShopStore } from '@/pinia/shop/shop.pinia'

export const useConfigStore = defineStore('config', {
	state: () => ({
		//
		chatConfig: {
			isSyncChatRelease: true,
			syncChatAllowShopsIds: [] as string[]
		},
		postReply: {
			isPostReplyRelease: true,
			postReplyAllowShopIds: [] as string[]
		}
	}),
	getters: {
		isShowChatMenu(state): boolean {
			if (state.chatConfig.isSyncChatRelease) {
				return true
			}

			const shopStore = useShopStore()
			const currentShopId = shopStore.currentShop?._id as string

			return this.chatConfig.syncChatAllowShopsIds.includes(currentShopId)
		},
		isShowPostReplyMenu(state): boolean {
			if (state.postReply.isPostReplyRelease) {
				return true
			}

			const shopStore = useShopStore()
			const currentShopId = shopStore.currentShop?._id as string

			return this.postReply.postReplyAllowShopIds.includes(currentShopId)
		}
	},
	actions: {
		//
		async getRemoteGlobalConfig() {
			try {
				const remoteConfig = firebase.remoteConfig()
				remoteConfig.settings.minimumFetchIntervalMillis = 1000
				await remoteConfig.fetchAndActivate()

				// Chat
				const syncChatAllowShopsIds = remoteConfig.getString('syncChatAllowShopsIds')
				const isSyncChatRelease = remoteConfig.getBoolean('isSyncChatRelease')

				this.chatConfig.isSyncChatRelease = isSyncChatRelease
				this.chatConfig.syncChatAllowShopsIds = syncChatAllowShopsIds.split(',') as string[]

				// Post Reply
				const postReplyAllowShopsIds = remoteConfig.getString('postReplyAllowShopsIds')
				const isPostReplyRelease = remoteConfig.getBoolean('isPostReplyRelease')

				this.postReply.isPostReplyRelease = isPostReplyRelease
				this.postReply.postReplyAllowShopIds = postReplyAllowShopsIds.split(',') as string[]
			} catch (error) {
				//   
			}
		}
	}
})