import { RouteRecordRaw } from 'vue-router'

const receipt: RouteRecordRaw = {
	path: '/receipt',
	name: 'receipt',
	meta: { auth: true },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'Receipt',
			component: () =>
				import('@/views/receipt/Receipt.vue')
		},
		{
			path: 'paperang',
			name: 'ShippingPaperang',
			component: () =>
				import('@/components/receipt/ReceiptPaperang.vue')
		},
	]
}
export default receipt