import { RouteRecordRaw } from 'vue-router'

const user: RouteRecordRaw = {
	path: '/user',
	name: 'user',
	meta: { auth: true, title: 'ผู้ดูแล' },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'UserDetail',
			component: () => import('@/views/user/index.vue'),
		}
	]
}
export default user