import { useMiddleAccount } from '@/use/middleAccount'

interface IDecission {
    regular: any,
    channel: any
}

export const decisionComponent = (params: IDecission) => {
	return {
		component: () => import('@/views/DynamicComponentDecision.vue'),
		props: { 
			component: () => {
				const { isChannelType } = useMiddleAccount()
				if (isChannelType.value) {
					return params.channel
				}
				return params.regular
			}
		}
	}
}