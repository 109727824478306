import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const product: RouteRecordRaw = {
	path: '/product',
	name: 'product',
	meta: { auth: true, title: 'สินค้า' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const { isDealerType } = useMiddleAccount()
		
		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'Product',
			component: () => import('@/views/product/Product.vue'),
		},
		{
			path: 'create',
			name: 'ProductCreate',
			component: () => import('@/views/product/ProductsForm.vue'),
		},
		{
			path: 'edit',
			name: 'ProductEdit',
			component: () => import('@/views/product/ProductsForm.vue'),
		},
		{
			path: 'product-set-create',
			name: 'ProductSetCreate',
			component: () => import('@/views/product/ProductSetForm.vue'),
		},
		{
			path: 'product-set-edit',
			name: 'ProductSetEdit',
			component: () => import('@/views/product/ProductSetForm.vue'),
		},
	],
}

export default product
