
import { defineComponent, computed } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { userCurrency } from '@/use/userCurrency'
import { useRouter} from 'vue-router'
import { paymentStore } from '@/pinia/payment/payment.pinia'

export default defineComponent({
	name: 'DialogBillPayment',
	setup() {
		const { mobileMode: isMobile } = useWindowSize()
		const { currencyMoneyDigits } = userCurrency()
		const payment = paymentStore()
		const isShow = computed(() => payment.dialogBillPayment.isShow)
		const isBtnClose = computed(() => payment.dialogBillPayment.isBtnClose)
		const router = useRouter()

		const handleClose = () => {
			payment.dialogBillPayment.isShow = false
			payment.isBlockBillPaymentOrder = false
			router.push({path: '/payment'})
		}

		const onCloseInBtn = () => {
			payment.dialogBillPayment.isShow = false
			payment.isBlockBillPaymentOrder = false
		}
		return {
			handleClose,
			isMobile,
			isShow,
			isBtnClose,
			dayjs,
			currencyMoneyDigits,
			onCloseInBtn,
		}
	},
})
