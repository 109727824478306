import { RouteRecordRaw } from 'vue-router'
import { useCredit } from '@/use/credits/useCredit'
import { useMiddleAccount } from '@/use/middleAccount'

const topUp: RouteRecordRaw = {
	path: '/top-up',
	name: 'topUp',
	meta: { auth: true, title: 'เครดิต' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		//
		const { isTopUpType } = useCredit()
		const { isChannelType } = useMiddleAccount()
		
		return isTopUpType.value && !isChannelType.value
	},
	children: [
		{
			path: '',
			name: 'topUpList',
			component: () =>
				import('@/views/top-up/index.vue')
		}
	]
}

export default topUp