import { RouteRecordRaw } from 'vue-router'

const fill: RouteRecordRaw = {
	path: '/fill',
	name: 'Dasboard',
	meta: { auth: false, title: 'รายละเอียดคำสั่งซื้อ' },
	component: () => import('@/layout/PlainLayout.vue'),
	children: [
		{
			path: 'order',
			name: 'DasboardMain',
			component: () => import('@/views/fill/order.vue'),
		}
	]
}
export default fill