// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { facebookAppId } from '@/config'

export const fetchFbSdk = (): Promise => {
	return new Promise((resolve, reject) => {
		// if (process.env.NODE_ENV === 'development') {
		// Chat widget
		(function(d, s, id) {
			// eslint-disable-next-line prefer-const
			let js, fjs = d.getElementsByTagName(s)[0]
			if (d.getElementById(id)) return
			// eslint-disable-next-line prefer-const
			js = d.createElement(s); js.id = id
			js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js'
			js.onload = function() {
				console.log('%CustomerChat:: loaded')
				resolve()
				  }
			js.onerror = function() {
				reject()
				console.error('%CustomerChat:: NOT loaded')
			}
			fjs.parentNode.insertBefore(js, fjs)
		}(document, 'script', 'facebook-jssdk'))
		// } else {
		// 	// Devmode resolve
		// 	resolve()
		// }
	})
}

export const initialFbSdk = (): Promise => {
	return new Promise((resolve) => {
		window.fbAsyncInit = function() {
			FB.init({
				appId			 : facebookAppId,
				// appId				 : '1105276556677249',	
				// appId			 : '950756488854885',
				xfbml            : true,
				version          : 'v13.0'
			})
			resolve()
		}
	})
}