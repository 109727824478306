import { IPaymentList } from '@/pinia/payment/type'
import { AxiosResponse } from 'axios'
import axios from '../instance'

export interface IPaymentParams {
	shopId: string;
	status: string;
	offset: number;
	limit: number;
}

export const fetchPaymentList = async (params: IPaymentParams): Promise<AxiosResponse<IPaymentList>> => {
	try {
		const response = await axios.get('/bill-payment', {
			params
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPaymentQR = async (billPaymentIds: string[], voucherId?: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/bill-payment/qr', {
			billPaymentIds,
			voucherId
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
