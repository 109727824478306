import { useConfigStore } from '@/pinia/config/config.pinia'
import { RouteRecordRaw } from 'vue-router'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useMiddleAccount } from '@/use/middleAccount'

const facebookChat: RouteRecordRaw = {
	path: '/chat',
	name: 'liveChatMain',
	meta: { auth: true, title: 'แชท' },
	component: () => import('@/layout/LayoutDefault.vue'),
	async beforeEnter() {
		const configStore = useConfigStore()
		await configStore.getRemoteGlobalConfig()
		if (!configStore.isShowChatMenu) {
			return { path: '/dashboard' } // redirect to dashboard
		}

		const { isDealerType } = useMiddleAccount()
		
		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'listPage',
			component: () => import('@/views/chat/index.vue'),
		},
		{
			path: ':pageId',
			name: 'chatPageId',
			component: () => import('@/views/chat/_pageId.vue')
		},
		{
			path: ':pageId/setting',
			name: 'chatPageSetting',
			component: () => import('@/views/chat/setting.vue')
		},
	]
}

export default facebookChat
