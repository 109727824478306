import { RouteRecordRaw } from 'vue-router'

const incoming: RouteRecordRaw = {
	path: '/incoming',
	name: 'incomingPage',
	meta: { auth: true, title: 'เร็วๆนี้' },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'comingSoon',
			component: () => import('@/views/incoming.vue')
		}
	]
}

export default incoming