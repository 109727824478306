import axios from '../instance'
import { CustomerSearchParams, CustomerSearchResponse, CustomerSearchExactParams, CustomerFeedback } from './customer.type'

export const fetchCustomerByPhoneNumber = async (params: CustomerSearchParams): Promise<CustomerSearchResponse> => {
	try {
		const { data } = await axios.get<CustomerSearchResponse>('/customer', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchCustomerExactMatchWithPhoneNumber = async (params: CustomerSearchExactParams): Promise<any> => {
	try {
		const { data } = await axios.get('/customer/order-analytics', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const createFeedbackApi = async (body: CustomerFeedback): Promise<any> => {
	try {
		const { data } = await axios.post('/customer/feedback', body)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
