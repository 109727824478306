import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const pointReward: RouteRecordRaw = {
	path: '/reward',
	name: 'Reward',
	meta: { auth: true, title: 'ของรางวัล' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const { isChannelType, isDealerType } = useMiddleAccount()
		if (isChannelType.value || isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'RewardPage',
			component: () =>
				import('@/views/reward/index.vue')
		},
		{
			path: 'history',
			name: 'RewardHistoryPage',
			component: () =>
				import('@/views/reward/history.vue')
		},
	]
}

export default pointReward
