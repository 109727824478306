export enum EVENT_NAME {
	clickPrintReceipt = 'clickPrintReceipt',
	clickPrintReceiptAbbreviated = 'clickPrintReceiptAbbreviated',
	clickReportProductOrderCount = 'clickReportProductOrderCount',
	clickImportOrderFromFile = 'clickImportOrderFromFile',
	clickImportProductFromFile = 'clickImportProductFromFile',
	selectPageReportAnalytics = 'selectPageReportAnalytics',
	createLiveCF = 'createLiveCF',
	startLiveCF = 'startLiveCF',
	voucherDetail = 'voucherDetail',
	sendMessageSyncChat = 'sendMessageSyncChat',
	fanpageSetting = 'fanpageSetting',
	tagSyncChat = 'tagSyncChat',
	onAddAddressFromCustomerSyncChat = 'onAddAddressFromCustomerSyncChat',
	searchTagSyncChat = 'searchTagSyncChat',
	sendFavoriteMessageSyncChat = 'sendFavoriteMessageSyncChat',
	clickIconSyncChatFromOrderPage = 'clickIconSyncChatFromOrderPage',
	copyFillinLink = 'copyFillinLink',
	confirmOrderOnFillInPage = 'confirmOrderOnFillInPage',
	onSeeCourierPricePDF = 'onSeeCourierPricePDF',
	switchPreviewModeInComment = 'switchPreviewModeInComment',
	copyTrackingNumberInOrderPage = 'copyTrackingNumberInOrderPage',
	settingReceiptPage = 'settingReceiptPage',
	rankingDetailMobile = 'rankingDetailMobile',
	rankingDetailDesktop = 'rankingDetailDesktop',
	selectOldCustomerFromSearchPhoneNumber = 'selectOldCustomerFromSearchPhoneNumber',
	oldCustomerDetail = 'oldCustomerDetail',
	importOrderFromFile = 'importOrderFromFile',
	importProductFromFile = 'importProductFromFile',
	uploadSlipOnCreateEditOrderPage = 'uploadSlipOnCreateEditOrderPage',
	exportExcelCOD = 'exportExcelCOD',
	exportExcelCODCalendar = 'exportExcelCODCalendar',
	exportExcelReport = 'exportExcelReport',
	exportExcelCost = 'exportExcelCost',
	exportExcelProduct = 'exportExcelProduct',
	exportExcelSocial = 'exportExcelSocial',
	selectPrintShippingSize = 'selectPrintShippingSize',
	selectPrintRecepitSize = 'selectPrintRecepitSize',
	// shippingFour = 'shippingFour',
	// shippingOne = 'shippingOne',
	// shippingMiniprinter = 'shippingMiniprinter',
	// shippingSix = 'shippingSix',
	// shippingSticker4x4 = 'shippingSticker4x4',
	// shippingSticker4x6 = 'shippingSticker4x6',
	// shippingSticker4x3 = 'shippingSticker4x3',
	seePickupCourier = 'seePickupCourier',
	seePickupThailandPostTutorial = 'seePickupThailandPostTutorial',
	seeCreditLogDetail = 'seeCreditLogDetail',
	searchProductButtonOnCreateEditOrderPage = 'searchProductButtonOnCreateEditOrderPage',
	goToSeePageAnalyticsFromSyncChatPage = 'goToSeePageAnalyticsFromSyncChatPage',
	clickTabSettingDetail = 'clickTabSettingDetail',
	clickTabReceiptDetail = 'clickTabReceiptDetail',
	clickTabSettingSMS = 'clickTabSettingSMS',
	onClicktoSMSHistory = 'onClicktoSMSHistory',
	onSubmitEditSMS = 'onSubmitEditSMS',
	clickTabSettingFillIn = 'clickTabSettingFillIn',
	onSubmitEditMessageTrackingSMS = 'onSubmitEditMessageTrackingSMS',
	onSubmitEditMessageCompleteOrderSMS = 'onSubmitEditMessageCompleteOrderSMS',
	onSubmitEditMessageSentOrderSMS = 'onSubmitEditMessageSentOrderSMS',

	// customer report
	onSortCustomerReportTable = 'customerReportPage_onSortCustomerReportTable',
	customerReportSeeCustomerDetail = 'customerReportPage_customerReportSeeCustomerDetail',
	customerReportOnTabChange = 'customerReportPage_customerReportOnTabChange',

	// line
	lineNotifyCodChat = 'lineNotifyCodChat',
	lineNotifyCourier = 'lineNotifyCourier',
	lineNotifyPendingChat = 'lineNotifyPendingChat',

	// report
	onSearchReportPage = 'onSearchReportPage',
	onSearchTrackingNumberInOrderListPage = 'onSearchTrackingNumberInOrderListPage',
	onSearchReportShipping = 'onSearchReportShipping',
	onSearchCODReportReconcileTab = 'onSearchCODReportReconcileTab',
	onSearchCODPendingReconcileTab = 'onSearchCODPendingReconcileTab',
	onSearchCustomerReport = 'onSearchCustomerReport',
	exportCustomerReport = 'exportCustomerReport',

	// cost
	onSearchCostReport = 'onSearchCostReport',
	onClickSeeChartCostReport = 'onClickSeeChartCostReport',
	onExportExcelCostReport = 'onExportExcelCostReport',
	onEditCostReport = 'onEditCostReport',
	onSubmitEditCostReport = 'onSubmitEditCostReport',
	onSeeDetailCostReportFromDashboard = 'onSeeDetailCostReportFromDashboard',
	onClickCalculate = 'onClickCalculate',
	onClickGotoCalculateRateFormShipmentPage = 'onClickGotoCalculateRateFormShipmentPage',
	onRemoveCalPage_Flash = 'onRemoveCalPage_Flash',
	onRemoveCalPage_Kerry = 'onRemoveCalPage_Kerry',
	onRemoveCalPage_Thailand_Post = 'onRemoveCalPage_Thailand_Post',
	onRemoveCalPage_Ninja_Van = 'onRemoveCalPage_Ninja_Van',
	onRemoveCalPage_Flash_Bulky = 'onRemoveCalPage_Flash_Bulky',

	// dashborad analytics
	onClickTodayFilterDashboard = 'onClickTodayFilterDashboard',
	onClickWeekFilterDashboard = 'onClickWeekFilterDashboard',
	onClick30DaysFilterDashboard = 'onClick30DaysFilterDashboard',
	onClickLastMonthFilterDashboard = 'onClickLastMonthFilterDashboard',
	onClickFilterDateRangeDashboard = 'onClickFilterDateRangeDashboard',
	onClickProductTabDashboard = 'onClickProductTabDashboard',
	onClickOverallTabDashboard = 'onClickOverallTabDashboard',
	onClickAdvanceSearchDashboard = 'onClickAdvanceSearchDashboard',
	onClickProvinceTabDashboard = 'onClickProvinceTabDashboard',
	onClickSelectPageDialogOnDashboard = 'onClickSelectPageDialogOnDashboard',

	// commission
	onClickTodayFilterCommission = 'onClickTodayFilterCommission',
	onClickWeekFilterCommission = 'onClickWeekFilterCommission',
	onClick30DaysFilterCommission = 'onClick30DaysFilterCommission',
	onClickLastMonthFilterCommission = 'onClickLastMonthFilterCommission',
	onClickFilterDateRangeCommission = 'onClickFilterDateRangeCommission',
	exportExcelCommission = 'exportExcelCommission',
	// Product Log
	onClickSeeProductLogDetail = 'onClickSeeProductLogDetail',
	onClickAdjustDialogProduct = 'onClickAdjustDialogProduct',
	onConfirmAdjustDialogProduct = 'onConfirmAdjustDialogProduct',

	// dashboard
	onOpenNewsAgain = 'onOpenNewsAgain',

	//facebook ads
	onOpenFacebookAdsTutorial = 'onOpenFacebookAdsTutorial',

	//OrderStatus
	onClickCopyTextInDialogErrorUnableSendMessage = 'onClickCopyTextInDialogErrorUnableSendMessage',
	onClickSeeChatFanPageInDialogErrorUnableSendMessage = 'onClickSeeChatFanPageInDialogErrorUnableSendMessage',

	//feedback
	onOpenDialogFeedback = 'onOpenDialogFeedback',

	//log verify slip
	onOpenLogVerifySlip = 'onOpenLogVerifySlip'
}
