import { ORDER_DOWNLOAD_RECRIPT } from '@/constants/route'
import { useGlobalStore } from '@/pinia/global.pinia'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export enum REACT_NATIVE_EVENT {
    SIGNOUT = 'SIGNOUT',
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',
	SELECT_SHOP = 'SELECT_SHOP',
	ON_CONNECT_PAGE = 'ON_CONNECT_PAGE',
	ON_REVOKE_PAGE = 'ON_REVOKE_PAGE',
	ON_CONNECT_LINE_NOTIFY = 'ON_CONNECT_LINE_NOTIFY',
	ON_SUCCESS_PICKUP_COURIER_CALL = 'ON_SUCCESS_PICKUP_COURIER_CALL',
	ON_CANCEL_PICKUP_COURIER_CALL = 'ON_CANCEL_PICKUP_COURIER_CALL',
	ON_CANCEL_CREATE_ORDER = 'ON_CANCEL_CREATE_ORDER',
	ON_SUCCESS_CHANGE_ORDER_STATUS = 'ON_SUCCESS_CHANGE_ORDER_STATUS', // ย้ายสถานะ
	ON_DOWNLOAD_PAYMENT_RECEIPT = 'ON_DOWNLOAD_PAYMENT_RECEIPT',
	ON_DELETE_ORDER_SUCCESS = 'ON_DELETE_ORDER_SUCCESS',

	// fetching data
	REDEEM_POINT = 'REDEEM_POINT',
	TOP_UP = 'TOP_UP',

	// emit href tracking link to react native
	ON_OPEN_TRACKING_LINK = 'ON_OPEN_TRACKING_LINK',

	// shop
	ON_DELETE_SHOP_COMPLETE = 'ON_DELETE_SHOP_COMPLETE',
}

export enum INCOMING_REACTIVE_NATIVE_EVENT {
	CHANGE_PAGE = 'CHANGE_PAGE',
	ON_SENT_CONNECT_FACEBOOK_TOKEN = 'ON_SENT_CONNECT_FACEBOOK_TOKEN',
	ON_UPDATE_ORDER_IDS = 'ON_UPDATE_ORDER_IDS',
	PRINT_RECRIPT_PAYMENT = 'PRINT_RECRIPT_PAYMENT',
	OPEN_DIALOG_CUSTOMER_FEEDBACK = 'OPEN_DIALOG_CUSTOMER_FEEDBACK'
}

export enum FILE_TYPE {
	xlsx = 'xlsx',
	pdf = 'pdf'
}

export type TConnectLine = {
	state: string,
	channelId: string,
	redirectUri: string
}

export const useReactNativeEvent = () => {

	const eventMessage = ref()
	const router = useRouter()
	const shippingStore = useShippingStore()
	const globalStore = useGlobalStore()

	const emitEvent = (eventName: REACT_NATIVE_EVENT, data?: any) => {
		const emitPayload = {
			eventName,
			data
		}

		const stringifyPayload = JSON.stringify(emitPayload)

		if (window.ReactNativeWebView) {
            // emit event to React Native
            window.ReactNativeWebView?.postMessage(stringifyPayload)
		}
	}

	const emitDownloadFile = (file: string, type: FILE_TYPE) => {
		const emitPayload = {
			file,
			type
		}
		emitEvent(REACT_NATIVE_EVENT.DOWNLOAD_FILE, emitPayload)
	}

	const emitSignout = () => {
		emitEvent(REACT_NATIVE_EVENT.SIGNOUT)
	}

	const emitSelectShop = (shopId: string) => {
		emitEvent(REACT_NATIVE_EVENT.SELECT_SHOP, shopId)
	}

	const emitConnectLineNotify = (connectLinePayload: TConnectLine) => {
		emitEvent(REACT_NATIVE_EVENT.ON_CONNECT_LINE_NOTIFY, connectLinePayload)
	}

	const listenerEventMessage = (handleFunction?: any) => {
		if (handleFunction) {
			window.addEventListener('message', handleFunction)
			return
		}
		// default function
		window.addEventListener('message', handlerEventFromReactNative)
	}

	const removeListenerEventMessage = (handleFunction?: any) => {
		if (handleFunction) {
			window.removeEventListener('message', handleFunction)
			return
		}
		window.removeEventListener('message', handlerEventFromReactNative)
	}


	// ให้เพื่อ React Native สามารถส่ง orderIDs มาเพื่ออัพเดทได้
	const onReactNativeUpdateOrderIds = (orderIds: string[]) => {
		shippingStore.updateOrderListId(orderIds)
	}

	const handlerEventFromReactNative = (event?: any) => {

		try {
			const eventData = JSON.parse(event?.data)
			
			if (eventData) {
				eventMessage.value = eventData

				if (eventData.eventName === INCOMING_REACTIVE_NATIVE_EVENT.CHANGE_PAGE) {
					router.push(eventData.data)
				}

				if (eventData.eventName === INCOMING_REACTIVE_NATIVE_EVENT.ON_UPDATE_ORDER_IDS) {
					onReactNativeUpdateOrderIds(eventData.data)
				}

				if (eventData.eventName === INCOMING_REACTIVE_NATIVE_EVENT.OPEN_DIALOG_CUSTOMER_FEEDBACK) {
					globalStore.openDialogCustomerFeedback()
				}
			}
			
		} catch {
			//
		} finally {
			//
		}
	}
	

	return {
		emitEvent,
		emitDownloadFile,
		emitSignout,
		emitSelectShop,
		emitConnectLineNotify,
		listenerEventMessage,
		removeListenerEventMessage,
		eventMessage,
	}
}
