import { baseURL } from '@/config'
import { TRANSACTION_STATUS } from '@/pinia/payment/type'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { topUpStore } from '@/pinia/top-up/top-up.pinia'
import { io, Socket } from 'socket.io-client'
import { onUnmounted, ref, computed } from 'vue'
import { useUserStore } from '@/pinia/user/user.pinia'
import { SHOP_CREDIT_TYPE } from '@/constants/credit'
import { REACT_NATIVE_EVENT, useReactNativeEvent } from '@/use/useReactNativeEvent'

export const useCredit = () => {

	const shopStore = useShopStore()
	const topUp = topUpStore()
	const creditSocket = ref<Socket>()
	const userStore = useUserStore()
	const { emitEvent } = useReactNativeEvent()

	const onClientCallback = async (payload: any) => {
		if (payload.status !== TRANSACTION_STATUS.PENDING) {
			// refetch table data
			const { selectedShopId } = shopStore
			await topUp.getTopUpTransactionList(selectedShopId as string)
			topUp.initialTransactionStatus = payload.status
			if (topUp.initialTransactionStatus === TRANSACTION_STATUS.APPROVED) {
				await userStore.getUserRoleInShopId({ shopId: selectedShopId as string })

				// update emit to react native
				emitEvent(REACT_NATIVE_EVENT.TOP_UP)
			}
			// Display dialog status
			topUp.isShowTopUpDialog = false // close dialog
			topUp.isShowDialogStatus = true // display status dialog
			topUp.clearAllQR() // clear all Old QR
		}
	}

	const onSubscribeSocket = (transactionId: string) => {
		const path = `${baseURL}transaction`
		creditSocket.value = io(path)

		creditSocket.value.emit('joinRoom', { room: transactionId /*  */ })
		creditSocket.value.on('toClient', onClientCallback)
	}

	const closeSocket = () => {
		if (creditSocket.value) {
			creditSocket.value.close()
		}
	}

	const isTopUpType = computed(() => {
		return userStore?.permission?.shopCreditType === SHOP_CREDIT_TYPE.TOP_UP || userStore?.permission?.settingConfig?.isDealer
	})

	onUnmounted(() => {
		closeSocket()
	})

	return {
		onSubscribeSocket,
		closeSocket,
		isTopUpType
	}
}