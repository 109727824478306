
export enum SHOP_CREDIT_TYPE {
    TOP_UP = 'TOP_UP',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    HALF_MONTH = 'HALF_MONTH',
	CHANNEL = 'CHANNEL',
}

export enum CREDIT_TRANSACTION_TYPE {
    TOP_UP = 'TOP_UP', // เติมเงิน
    PAYMENT = 'PAYMENT', // จ่ายตอนย้าย
    REFUND = 'REFUND', // คืนตอนยกเลิก
	ADJUST = 'ADJUST', // ปรับสิ้นวัน
	REDEEM = 'REDEEM', // แลกคูปอง
	WITHDRAW = 'WITHDRAW', // ถอนเงิน
	PAYMENT_RETURN = 'PAYMENT_RETURN', // คืนเงิน
	SMS_PAYMENT = 'SMS_PAYMENT', // ค่า SMS
	ADJUST_OPPOSE = 'ADJUST_OPPOSE', // ปรับเครดิตหลังจากมีการเข้าสายพานแล้วน้ำหนักเปลี่ยน
}

export type KeyTransactionMap = {
	name: string
}

export type KeyTransactionTypeMap = {
	[key in CREDIT_TRANSACTION_TYPE]: KeyTransactionMap
}

export const transactionTypeMap: KeyTransactionTypeMap = {
	[CREDIT_TRANSACTION_TYPE.TOP_UP]: {
		name: 'เติมเงินผ่าน PromptPay',
	},
	[CREDIT_TRANSACTION_TYPE.PAYMENT]: {
		name: 'ค่าจัดส่งออเดอร์',
	},
	[CREDIT_TRANSACTION_TYPE.REFUND]: {
		name: 'ยกเลิกออเดอร์',
	},
	[CREDIT_TRANSACTION_TYPE.ADJUST]: {
		name: 'ค่าส่วนต่างจัดส่งออเดอร์',
	},
	[CREDIT_TRANSACTION_TYPE.REDEEM]: {
		name: 'คูปอง',
	},
	[CREDIT_TRANSACTION_TYPE.WITHDRAW]: {
		name: 'ถอนเงิน'
	},
	[CREDIT_TRANSACTION_TYPE.PAYMENT_RETURN]: {
		name: 'ค่าพัสดุตีกลับ'
	},
	[CREDIT_TRANSACTION_TYPE.SMS_PAYMENT]: {
		name: 'ค่า SMS'
	},
	[CREDIT_TRANSACTION_TYPE.ADJUST_OPPOSE]: {
		name: 'ค่าส่วนต่างจัดส่งออเดอร์',
	},
}