import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const liveStream: RouteRecordRaw = {
	path: '/live-stream',
	name: 'liveStreamMain',
	meta: { auth: true, title: 'ถ่ายทอดสด' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const { isDealerType } = useMiddleAccount()

		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: ':pageId',
			name: 'pageTableList',
			component: () => import('@/views/live-stream/page/_index.vue'),
			// component: () => import('@/views/incoming.vue')
		},
		{
			path: ':pageId/setup',
			name: 'liveStreamCreate',
			component: () => import('@/views/live-stream/page/setup.vue')
			// component: () => import('@/views/incoming.vue')
		},
		{
			path: 'page',
			name: 'liveStreamList',
			component: () => import('@/views/live-stream/page.vue'),
			// component: () => import('@/views/incoming.vue')
		},
		{
			path: ':pageId/live/:liveId',
			name: 'livePage',
			component: () => import('@/views/live-stream/_liveId.vue')
			// component: () => import('@/views/incoming.vue')
		}
	]
}

export default liveStream
