import { RouteRecordRaw } from 'vue-router'

const loading: RouteRecordRaw = {
	path: '/loading',
	name: 'loadingPage',
	meta: { auth: false },
	component: () => import('@/FirstLoadingScreen.vue'),
}

export default loading
