import axios from '../instance'
import { GenerateQRPayload, GetSubscriptionPayload, ResponseGenerateQR, SubscriptionRes } from './subscription.type'

export const getSubscriptionList = async (payload: GetSubscriptionPayload): Promise<SubscriptionRes[]> => {
	const { data } = await axios.post<SubscriptionRes[]>('/subscription', payload)
	return data
}

export const generateQRPayment = async (payload: GenerateQRPayload): Promise<ResponseGenerateQR> => {
	const { data } = await axios.post<ResponseGenerateQR>('/subscription/qr', payload)
	return data
}
