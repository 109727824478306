import { AxiosResponse } from 'axios'
import axios from './instance'
import { VAT_TYPE } from '@/config/vat'

export interface ICreateShopPayload {
	name: string
	desc: string
	email: string
	phoneNo: string
	article: string
	instagramLink?: string
	facebookLink?: string
	lineLink?: string
	image: string
	address: {
		desc1: string
		provinceCode: number | null
		districtCode: number | null
		subDistrictCode: number | null
		zipCode: number | null
	}
}

export const createShop = async (payload: ICreateShopPayload): Promise<any> => {
	try {
		const response = await axios.post('/shop/create', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export type ShopList = Array<{ _id: string; name: string; image: string }>

export const shopList = async (): Promise<AxiosResponse<ShopList>> => {
	try {
		const response = await axios.get('/shop')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface ShopDetail {
	_id: string
	userId: string
	email: string
	desc: string
	name: string
	phoneNo: string
	article: {
		code: number
		name: string
		_id: string
	}
	facebookLink: string
	image: string
	instagramLink: string
	lineLink: string
	updatedAt: string
	createdAt: string
	address: []
	bank: []
}

export const getShopDetail = async (
	shopId: string | null
): Promise<ShopDetail> => {
	try {
		const { data } = await axios.get(`/shop/detail/${shopId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface EditShopPayload {
	_id: string
	email: string
	desc: string
	name: string
	phoneNo: string
	article: string
	instagramLink: string
	facebookLink: string
	lineLink: string
	image: string
}

export const editShop = async (payload: EditShopPayload): Promise<any> => {
	try {
		const response = await axios.put(`/shop/edit/${payload._id}`, payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface ShopBankPayload {
	shopId: string | null
	bankId?: string | null
	accountName: string
	accountNumber: string
	accountIssuerId: string
}

export interface IBank {
    _id:           string;
    deleted:       boolean;
    deletedAt:     null;
    accountName:   string;
    accountNumber: string;
    accountIssuer: AccountIssuer;
    updatedAt:     Date;
    createdAt:     Date;
    accountType:   string;
}

export interface AccountIssuer {
    _id:   string;
    name:  string;
    code:  string;
    image: string;
}

export const addBankToShop = async (payload: ShopBankPayload): Promise<any> => {
	try {
		const response = await axios.post('/shop/bank/add', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const editBankInShop = async (
	payload: ShopBankPayload
): Promise<any> => {
	try {
		const response = await axios.put('/shop/bank/edit', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteBankIdShop = async (bankId: string): Promise<any> => {
	try {
		const response = await axios.delete(`/shop/bank/remove/${bankId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteShop = async (shopId: string): Promise<any> => {
	try {
		const response = await axios.delete(`/shop/delete/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface KycPayload {
	type: string
	shopId: string
	info: Info
	address: Address
	filePath: any
	otp: string
	reference: string
}
export interface Address {
	desc1: string
	provinceCode: number | null
	districtCode: number | null
	subDistrictCode: number | null
	zipCode: number | null
}
export interface Info {
	taxId?: string
	companyName?: string
	citizenId?: string
	firstname: string
	lastname: string
	phoneNo: string
}

export const uploadKyc = async (
	payload: KycPayload
): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.post('/shop/kyc', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getKycDetail = async (
	shopId: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get(`/shop/kyc/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getDashboardShop = async (
	shopId: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get(`/shop/dashboard/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getUserInShop = async (
	shopId: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get(`/shop/user/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface InvitePayload {
	shopId: string
	userId: string
	roleId: string
}

export const inviteUserToShop = async (
	payload: InvitePayload
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/invite/user', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface RevokeUserPayloadI {
	shopId: string
	userId: string
}

export const revokeUserFromShop = async (
	payload: RevokeUserPayloadI
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/revoke/user', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getAddressList = async (
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/address/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getBankList = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/bank/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getBillPayment = async (
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/bill-payment/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getShopSetting = async (
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/setting-config/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface IReceiptPayload {
	receiptName: string
	receiptPhoneNo: string
	receiptAddress: string
	receiptTaxId: string
	receiptPrefix: string
}

export interface IVatPayload {
	receiptIsVat: boolean
	vatType: VAT_TYPE
}

export const updateShopSetting = async (
	shopId: string,
	key: string,
	value: any
): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(`/shop/setting-config/${shopId}`, {
			[key]: value,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOTPShopKyc = async (
	shopId: string,
	phoneNo: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/kyc/verify-otp', {
			shopId,
			phoneNo,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

// Line
export enum StateType {
	parcelLineNotifyToken = 'parcelLineNotifyToken',
	syncChatResponseLineNotifyToken = 'syncChatResponseLineNotifyToken',
	transferCodLineNotifyToken = 'transferCodLineNotifyToken',
	summaryDailyReportLineNotifyToken = 'summaryDailyReportLineNotifyToken'
}

export interface ILineTokenPayload {
	code: string
	redirect_uri: string
	shopId: string
	state: StateType
}

export const connectLineNotifyToken = async (
	payload: ILineTokenPayload
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/connect-line-notify', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateReceiptSetting = async (
	shopId: string,
	payload: IReceiptPayload | null = null
): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(
			`/shop/setting-config/${shopId}`,
			payload
		)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const updateVatSetting = async (
	shopId: string,
	payload: IVatPayload | null = null
): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(
			`/shop/setting-config/set-vat/${shopId}`,
			payload
		)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export interface IShopCODDetail {
    accountName:   string;
    accountNumber: string;
    accountIssuer: AccountIssuer;
}

export interface AccountIssuer {
    _id:   string;
    name:  string;
    code:  string;
    image: string;
}


export const getShopCODDetail = async (
	shopId: string
): Promise<IShopCODDetail> => {
	try {
		const { data } = await axios.get(`/shop/account-cod/${shopId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateUserPermissionInShop = async (
	userId: string,
	roleId: string
): Promise<IShopCODDetail> => {
	try {
		const { data } = await axios.post('/shop/edit-role/user', {
			_id: userId,
			roleId
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}