
import { defineComponent, computed, ref, watch, onUnmounted, nextTick, ComputedRef } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { userCurrency } from '@/use/userCurrency'
import Swiper from 'swiper'
//swiper
import 'swiper/swiper.min.css'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { IInboxImageDetail } from '@/pinia/shop/types'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { useGlobalStore } from '@/pinia/global.pinia'

export default defineComponent({
	name: 'DialogImagePromotion',
	setup() {
		const { mobileMode: isMobile } = useWindowSize()
		const shopStore = useShopStore()
		const globalStore = useGlobalStore()
		const { currencyMoneyDigits } = userCurrency()
		const inboxImageDetail: ComputedRef<IInboxImageDetail[]> = computed((): any => shopStore.inboxImageDetail)
		const isNotShowAny = ref<boolean>(false)
		const mySwiper = ref<any>(null)
		const refImages = ref()
		const swiperInterval = ref()
		const isOpen = ref(false)
		const { logEvent } = useAnalytics()
		const handleClose = () => {
			shopStore.updateIsDialogInboxImage(false)
		}
		watch(
			() => inboxImageDetail.value,
			(inboxImage) => {
				if (inboxImage?.length && !mySwiper.value) {
					nextTick(() => {
						mySwiper.value = new Swiper(refImages.value, {
							spaceBetween: 30,
						})

						if (shopStore.isDialogImage) {
							setIntervalSwiper() // hack cause auto play not working here
						}
					})
				}
			}, 
			{
				immediate: true
			}
		)

		const setIntervalSwiper = () => {
			swiperInterval.value = setInterval(() => {
				if (!mySwiper.value.isEnd) {
					mySwiper.value.slideNext()
				} else {
					mySwiper.value.slideTo(0)
				}
			}, 2500)
		}

		const onMouseEnter = () => {
			clearInterval(swiperInterval.value)
		}

		const onMouseLeave = () => {
			setIntervalSwiper()
		}
		const handleOpenDialogCustomerFeedback = () => {
			globalStore.openDialogCustomerFeedback()
			logEvent({ eventName: EVENT_NAME.onOpenDialogFeedback })
		}

		const slideTo = (index: number) => {
			clearInterval(swiperInterval.value)
			if (mySwiper.value) {
				mySwiper.value.slideTo(index)
			}
		}

		const gotoLink = (link:string , title: string) => {
			
			if(title){
				const trimmedTitle = title.replace(/\s/g, '_')
				const event = 'clickDialogNewsTitle_'+ trimmedTitle
				logEvent({ eventName: event })
			}
			
			if(link){
				window.open(link,'_blank')
			}
		}
		
		onUnmounted(() => {
			clearInterval(swiperInterval.value)
		})

		return {
			handleClose,
			isMobile,
			inboxImageDetail,
			dayjs,
			currencyMoneyDigits,
			isNotShowAny,
			mySwiper,
			refImages,
			onMouseEnter,
			onMouseLeave,
			slideTo,
			shopStore,
			gotoLink,
			isOpen,
			handleOpenDialogCustomerFeedback
		}
		
	},
})
