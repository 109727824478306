import axios from '../instance'
import { AddressI } from './address.api'
import { AxiosResponse } from 'axios'
export interface ISearchAddress {
    name: string;
    code: number;
    districts: {
        name: string;
        code: number;
        subDistricts: {
            name: string;
            code: number;
            zipCode: number;
        }
    }
}

export const getSearchAddress = async (search: string|null): Promise<ISearchAddress[]> => {
	try {
		const { data } = await axios.get('/address', {
			params: {
				search
			}
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export interface IProvinces {
	name: string;
	code: number;
	districts: any;
}

export interface ISubDistricts {
	name: string;
	code: number;
	subDistricts: any;
}


export const getAddressVersion = async (): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/address/version')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getProvince = async (): Promise<IProvinces[]> => {
	try {
		const { data } = await axios.get('/address/provinces')
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getAllProvince = async (): Promise<AddressI[]> => {
	try {
		const { data } = await axios.get('/address/all')
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface IDistrict {
	name: string;
	code: number;
	districts: Array<any>;
}

export const getDistrictFromProvince = async (provinceCode: number): Promise<IDistrict> => {
	// /address/districts
	try {
		const { data } = await axios.get('/address/districts', {
			params: { provinceCode }
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface ISubDistrict extends IDistrict {
	subDistrict: {
		name: string;
		code: number;
		subDistricts: Array<any>;
	}
}

export const getSubDistrictFromDistrict = async (districtCode: number): Promise<ISubDistrict> => {
	try {
		const { data } = await axios.get('/address/subDistricts', {
			params: { districtCode }
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}


export const splitAddress = async (fullAddress: string): Promise<any> => {
	try {
		const response = await axios.get('/address/split', {
			params: { search: fullAddress }
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}