export type DefaultResponse = {
    success: string
}

export enum KycStatus {
	WAITING = 'WAITING',
	IN_REVIEW = 'IN_REVIEW',
	APPROVE = 'APPROVE',
	REJECT = 'REJECT'
}

export enum FacebookPageErrorDesc {
	PAGE_ACCESS_TOKEN_PERMISSION_INVALID = 'PAGE_ACCESS_TOKEN_PERMISSION_INVALID'
}

export enum ERROR_TYPE {
	USER_NOT_FOUND = 'USER_NOT_FOUND'
}

export enum DashboardType {
	FACEBOOK = 'FACEBOOK',
	TIKTOK = 'TIKTOK',
	LAZADA = 'LAZADA',
	SHOPEE = 'SHOPEE',
	ETC = 'ETC'
}
