import { EVENT_NAME } from '@/constants/analytics'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import firebase from 'firebase/app'

type AnalyticsFunction = {
    logEvent(eventpayload: LogEventRequest): void
}

type LogEventRequest = {
    eventName: EVENT_NAME | any,
    eventPayload?: any
}

export const useAnalytics = (): AnalyticsFunction => {

	const shopStore = useShopStore()

	const defaltPayload = {
		shopId: shopStore.selectedShopId as string,
		userAgent: navigator?.userAgent || 'unknown'
	}
    
	const analytics = firebase.analytics()

	const logEvent = (eventPayload: LogEventRequest) => {
		analytics.logEvent(eventPayload.eventName, {
			...defaltPayload,
			...eventPayload.eventPayload
		})
	}

	return {
		logEvent
	}
}