export enum Roles {
    OWNER = 'OWNER',
	MANAGER = 'MANAGER',
    ADMIN = 'ADMIN',
}

export const rolesMap = {
	[Roles.OWNER]: {
		text: 'เจ้าของร้าน',
		type: '',
		pros: ['เข้าถึงได้ทุกเมนูของร้าน'],
		cons: [],
	},
	[Roles.MANAGER]: {
		text: 'ผู้ดูแล',
		type: 'success',
		pros: ['เข้าถึงได้ทุกเมนูของร้าน'],
		cons: ['ไม่สามารถตั้งค่าร้านค้าได้'],
	},
	[Roles.ADMIN]: {
		text: 'แอดมิน',
		type: 'info',
		pros: ['เข้าถึงได้ทุกเมนูของร้าน'],
		cons: ['ไม่สามารถดูกำไรขาดทุนได้', 'ไม่สามารถเข้าถึงข้อมูล COD ได้', 'ไม่สามารถตั้งค่าร้านค้าได้'],
	},
}

type RoleDetail = {
	text: string
	type: string
	pros: string[]
	cons: string[]
}

export const roleDetail = (role: Roles): RoleDetail | null => {
	const roleMap = rolesMap[role]

	if (roleMap) {
		return roleMap
	}
	return null
}
