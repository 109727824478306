import { ITopUpTransactionList, ITopUpAmountList } from '@/pinia/top-up/type'
import { AxiosResponse } from 'axios'
import axios from '../instance'

export interface IPaymentParams {
  shopId: string;
  offset: number;
  limit: number;
  search: string;
}
  

export const fetchTopUpTransactionList = async (
	params: IPaymentParams
): Promise<AxiosResponse<ITopUpTransactionList>> => {
	try {
		const response = await axios.get('/credit/transaction', {
			params,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchTopUpAmountList = async (shopId: string): Promise<
  AxiosResponse<ITopUpAmountList>
> => {
	try {
		const response = await axios.get('/credit/amount', { params: { shopId } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const fetchPaymentQR = async (
	amount: number,
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/credit/qr', {
			amount,
			shopId,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
