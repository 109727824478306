import { RouteRecordRaw } from 'vue-router'

const authenticationRoute: RouteRecordRaw = {
	path: '/',
	name: 'authentication',
	redirect: '/login',
	meta: { auth: false },
	component: () => import('@/layout/login-register/LayoutLoginRegister.vue'),
	children: [
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/login.vue')
		},
		{
			path: '/app-login',
			name: 'AppLogin',
			component: () => import('@/views/app-login.vue')
		},
	]
}

export default authenticationRoute