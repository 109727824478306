import { AxiosResponse } from 'axios'
import axios from '../instance'
import { CODPendingParams, FeeParams, GetReportOrderListParams, ReconcileOrderParams, ReconcileParams, ITransactionParams, SocialPageParams, SocialReportPageResponse, IGetCustomerParams, IGetCostParams, IUpdateCostPayload, DashboardAnalyticsResponse, CODCalendarParams, CodCalendar, IFeeOpposeParams, CodCalendarParams } from './type'

export const getReportOrderList = async (params: GetReportOrderListParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/report/order', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReportOrderGenerateData = async (params: GetReportOrderListParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/report/order/generate', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcile = async (params: ReconcileParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report/reconcile', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcileOrder = async (params: ReconcileOrderParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report/reconcile/order', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getFee = async (params: FeeParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report/fee', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCodPending = async (params: CODPendingParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report/cod-pending', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCodCalendar = async (params: CODCalendarParams): Promise<CodCalendar[]> => {
	try {
		const response = await axios.get('/report/cod-calendar', { params })
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getTransactionHistory = async (params: ITransactionParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report/credit-txn', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getSocialReport = async (payload: SocialPageParams): Promise<AxiosResponse<SocialReportPageResponse>> => {
	try {
		const response = await axios.post('/report/analytics', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCustomerReport = async (params: IGetCustomerParams): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.get('/report/customer', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCostInShop = async (params: IGetCostParams): Promise<AxiosResponse<DashboardAnalyticsResponse>> => {
	try {
		const response = await axios.post('/dashboard-analytics', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateCost = async (payload: IUpdateCostPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/dashboard-analytics/update', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const verifySlipApi = async (payload: any): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/verify-slip', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const findCodCalendarOrders = async (params: CodCalendarParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/report/cod-calendar/order', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getFeeOppose = async (params: IFeeOpposeParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/report/fee-oppose', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
