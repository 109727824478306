import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import firebase from 'firebase/app'
import { baseURL } from '@/config'
import router from '@/router'
import { useShopStore } from '@/pinia/shop/shop.pinia'

import { v4 as uuidv4 } from 'uuid'
import { useUserStore } from '@/pinia/user/user.pinia'
import { ERROR_TYPE, FacebookPageErrorDesc } from './type'
import { ElNotification } from 'element-plus'
import { useRoute } from 'vue-router'
import { useGlobalStore } from '@/pinia/global.pinia'
import { SUBSCRIPTION_ERROR } from '@/type/global.type'
import { computed } from 'vue'

const apiInstance: AxiosInstance = axios.create({
	baseURL
}) 
const isReactNativeWebview = computed(() => window.ReactNativeWebView)

apiInstance.interceptors.request.use(async (configs: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
	const shopStore = useShopStore()
	const userStore = useUserStore()
	
	const { userData } = userStore
	
	if (userData) {
		const token = await firebase.auth().currentUser?.getIdToken()
		configs.headers.Authorization = `Bearer ${token}`
	}

	const { selectedShopId } = shopStore

	if (selectedShopId) {
		configs.headers['x-shop-id'] = selectedShopId
	}
	// ADD x-request-id along with header to serverside
	configs.headers['x-request-id'] = uuidv4()

	return configs
})

apiInstance.interceptors.response.use(
	(response) => {		
		return response
	},
	(error) => {
		const globalStore = useGlobalStore()
		if (error.response) {
			if (error.response.status === 401) {
				// Do seomthing
				return router.push('/login')
			}

			if (error.response?.data?.resCode === 403) {
			// handle
				const { resDesc } = error.response?.data
				if (resDesc === SUBSCRIPTION_ERROR.CHANNEL_COURIER_NOT_SUPPORT ||
					resDesc === SUBSCRIPTION_ERROR.NOT_SUBSCRIPTION_YET ||
					resDesc === SUBSCRIPTION_ERROR.SUBSCRIPTION_EXPIRED
				) {
					globalStore.subscription.status = resDesc
					globalStore.subscription.isShowBlockDialog = true
				}
			}

			if (error.response?.data?.resDesc === FacebookPageErrorDesc.PAGE_ACCESS_TOKEN_PERMISSION_INVALID) {
				ElNotification({
					title: 'พบข้อผิดพลาด',
					message: 'การเชื่อมต่อเพจของคุณหมดอายุ กรุณาเชื่อมต่อใหม่อีกครั้ง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
				const path = router.currentRoute?.value?.fullPath
				if (path.includes('/live-stream')) {
					return router.push('/live-stream/page')
				}
				if (path.includes('/chat')) {
					return router.push('/chat')
				}
				if (path.includes('/page-comment')) {
					return router.push('/page-comment')
				}
				return router.push('/chat')
			}

			// user has been remove from shop
			if (error.response?.data?.resDesc === ERROR_TYPE.USER_NOT_FOUND) {
				const shopStore = useShopStore()

				ElNotification({
					title: 'พบข้อผิดพลาด',
					message: 'ไม่พบผู้ใช้งานนี้ในร้านค้า',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})

				// clear selected shop Id
				shopStore.updateSelectedShop(null)
				
				return router.push('/shop')
			}
		}
		return Promise.reject(error)
	}
)

export default apiInstance