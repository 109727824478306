import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const postCf: RouteRecordRaw = {
	path: '/page-cf',
	name: 'postCfMain',
	meta: { auth: true, title: 'ตั้งค่า cf ใต้โพสต์' },
	components: {
		default: () => import('@/layout/LayoutDefault.vue'),
	},
	beforeEnter: () => {
		const { isDealerType } = useMiddleAccount()

		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'pagePostCfTableList',
			component: () => import('@/views/post-cf/index.vue'),
		},
		{
			path: ':pageId',
			name: 'postCfCreate',
			component: () => import('@/views/post-cf/_pageId.vue'),
		},
		{
			path: ':pageId/:mentId',
			name: 'postCfCreateOrEdit',
			component: () => import('@/views/post-cf/_createCFOrEditCF.vue'),
		},
		{
			path: ':pageId/cf/:cfId',
			name: 'cfPostDetailPage',
			component: () => import('@/views/post-cf/_cfId.vue')
		}
	],
}

export default postCf
