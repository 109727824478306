import { getConfigEnv } from './getConfigEnv'

const { baseURL, facebookAppId, firebaseConfig, lineNotifyClientId, landingBaseUrl } = getConfigEnv()

export {
	baseURL,
	firebaseConfig,
	facebookAppId,
	lineNotifyClientId,
	landingBaseUrl,
}