import { defineStore } from 'pinia'
import { SUBSCRIPTION_ERROR } from '@/type/global.type'

type State = {
    subscription: {
        isShowBlockDialog: boolean,
        status: SUBSCRIPTION_ERROR,
    },
	isOpenDialogCustomerFeedback: boolean,
}

export const useGlobalStore = defineStore('global', {

	state: (): State => ({
		subscription: {
			isShowBlockDialog: false,
			status: SUBSCRIPTION_ERROR.NOT_SUBSCRIPTION_YET,
		},
		isOpenDialogCustomerFeedback: false,
	}),
	actions: {
		openDialogCustomerFeedback () {
			this.isOpenDialogCustomerFeedback = true
		},
		closeDialogCustomerFeedback () {
			this.isOpenDialogCustomerFeedback = false
		}
	}
})
