import { RouteRecordRaw } from 'vue-router'

const error: RouteRecordRaw = {
	path: '/:pathMatch(.*)*',
	name: 'ErrorPage',
	meta: { auth: false },
	component: () => import('@/layout/PlainLayout.vue'),
	children: [
		{
			path: '',
			name: '404',
			component: () => import('@/views/error.vue')
		}
	]
}

export default error