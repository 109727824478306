import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const order: RouteRecordRaw = {
	path: '/order',
	name: 'order',
	meta: { auth: true, title: 'คำสั่งซื้อ' },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'OrderList',
			component: () => import('@/views/order/Order.vue'),
		},
		{
			path: 'create',
			name: 'OrderCreate',
			component: () => import('@/components/order/OrderForms.vue'),
		},
		{
			path: 'edit',
			name: 'OrderEdit',
			component: () => import('@/components/order/OrderForms.vue'),
		},
		{
			path: 'detail',
			name: 'OrderDetail',
			component: () => import('@/views/order/OrderDetail.vue'),
		},
		{
			path: 'order-product-check-list',
			name: 'OrderProductStockCheckList',
			component: () => import('@/views/order/order-product-check-list.vue'),
		},
		{
			path: 'claim',
			name: 'ClaimOrderList',
			beforeEnter: () => {
				const { isChannelType } = useMiddleAccount()
				if (isChannelType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/order/claim/index.vue')
		},
		{
			path: 'claim/:orderId',
			name: 'ClaimOrder',
			beforeEnter: () => {
				const { isChannelType } = useMiddleAccount()
				if (isChannelType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/order/claim/claimId.vue'),
		},
		{
			path: 'order-receipt-payment',
			name: 'orderReceiptPayment',
			component: () => import('@/components/order/order-detail/PaymentReceivedForm.vue')
		},
		{
			meta: { title: 'ตรวจสอบสลิป' },
			path: 'verify-slip',
			name: 'VerifySlip',
			beforeEnter: ():any => {
				const { isChannelType, isDealerType } = useMiddleAccount()
				if (isChannelType.value || isDealerType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/verify-slip/index.vue'),
		},
		
	]
}

export default order