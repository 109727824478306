import { Router } from 'vue-router'
import { SHOP_STATUS } from '@/api/user/user.type'
import { paymentStore } from '@/pinia/payment/payment.pinia'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { SHOP_CREDIT_TYPE } from '@/constants/credit'
import { permission } from './permission'
// auth middleware
// Guard
let suspend = 0
const initialMiddleware = (router: Router): void => {
	router.beforeEach((to, from) => {
		const shopStore = useShopStore()
		const userStore = useUserStore()

		// ที่ต้องใช้ === false แทน ! เพราะเผื่อมี path เก่าๆ ไม่ได้ใส่ meta
		// ทำให้อาจจะ undefined แต่ยังจำเป็นต้อง auth
		if (to.meta?.auth === false) {
			return true
		}
		// Allow /fill/order page
		if (to.path === '/fill/order') {
			return true
		}
		// temp auth
		if (to.path === '/app-login' || to.path === '/test-download') {
			return true
		}
		// not auth
		if (!userStore.userData && to.meta?.auth) {
			return '/login'
		}
		// not select shop first
		const validatePath = ['/shop', '/login']
		const isPathInclude = validatePath.some(path => to.path.includes(path))
		if (!isPathInclude && !shopStore.selectedShopId) {
			return '/shop'
		}

		// Check Suspend shop
		if (userStore?.permission?.shopStatus === SHOP_STATUS.SUSPEND && suspend < 1 && to.path !== '/shop') {
			suspend++
			return '/suspend'
		}
	    const payment = paymentStore()
		const validateOrderPath = ['/order', '/order/create', '/order/edit', '/order/detail']
		const isValidateOrderPath = validateOrderPath.some(path => to.path.includes(path))
		if (!isValidateOrderPath) {
			payment.isBlockBillPaymentOrder = true
		}
		// if (to.path === '/top-up' && userStore?.permission?.shopCreditType !== SHOP_CREDIT_TYPE.TOP_UP && !userStore?.permission?.settingConfig.enableTrackingSMS) {
		// 	return '/dashboard'
		// }
	})


	//
	router.beforeEach((to, next) => {
		if (to?.meta?.title) {
			document.title = `${to.meta.title} | Order Plus`
		}
	})


	router.beforeEach(permission)
}

export default initialMiddleware
