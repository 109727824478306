import { App } from 'vue'
import initialElementPlus from './element-plus'
import initialElementPlusIcon from './icons'
import initialVueBarcode from './vue-barcode'
import initialVueClipboard from './vue-clipboard'
import initialVueQrCode from './vue-qrcode'
import initialVuelidate from './vuelidate'
import initialGlobalInstance from './global-instance'
import initialVueClickOutside from './vue-click-outside'

export default (app: App<Element>): void => {
	initialGlobalInstance(app)
	initialElementPlus(app)
	initialElementPlusIcon(app)
	initialVueBarcode(app)
	initialVueClipboard(app)
	initialVueQrCode(app)
	initialVuelidate(app)
	initialVueClickOutside(app)
}
