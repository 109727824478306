import { createApp } from 'vue'
import firebase from 'firebase/app'
import App from './App.vue'
import FirstLoadingScreen from './FirstLoadingScreen.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import initialMiddleware from './middlewares'
// Plugins
import initialAllPlugins from '@/plugins'
import initialFirebase from '@/plugins/firebase'

import { initialFbSdk, fetchFbSdk } from '@/plugins/facebook-sdk'

//Global Style
import '@/assets/sass/globalStyles.scss'
import { useUserStore } from './pinia/user/user.pinia'
import { useConfigStore } from '@/pinia/config/config.pinia'
import { useCourierStore } from '@/pinia/courier/courier.pinia'

let count = 0

function startVueApp() {
	const app = createApp(App)
	const pinia = createPinia()
	pinia.use(piniaPluginPersistedstate) // persisted plugins for pinia
	app.use(pinia)
	const shopStore = useShopStore()
	const userStore = useUserStore()
	const configStore = useConfigStore()
	const courierStore = useCourierStore()

	const loadingScreen = createApp(FirstLoadingScreen)

	// Mount First Loading
	loadingScreen.mount('#app')
	// Initial Plugins
	// Subscribe firebase
	initialFirebase()

	firebase.auth().onAuthStateChanged(async (user) => {
		count++
		// Intial APp but if User trigger change again dont do Mount App
		userStore.updateUserData(user)
		// if user Exist
		if (user) {
			try {
				// Get All shop
				// get all courier
				await Promise.all([shopStore.getAllShop(), courierStore.getCourierList()])

				const shopId = shopStore.selectedShopId
				// Get permission In shop if exist selected Shop key
				// and get initial address for create order page
				if (shopId) {
					await Promise.all([
						userStore.getUserRoleInShopId({ shopId }),
						// shopStore.actionFetchAndUpdateAddress(shopId)
					])
				}
			} catch (error) {
				// if something Error when get shop and user will redirect to shop page
				// await router.push('/shop')
			}
		}
		// End
		if (count === 1) {
			// Unmount loading page
			loadingScreen.unmount()
			// wait initial Facebook SDK
			await fetchFbSdk()
			await initialFbSdk()

			// initial all middleware
			initialMiddleware(router)
			//initial global plugins
			initialAllPlugins(app)

			// get remote Config global
			// คอมเม้นไว้ชั่วคราวเนื่องจาก firebase ติด 503
			// configStore.getRemoteGlobalConfig()

		

			app.use(router)
			// Mount App
			app.mount('#app')
		}
	})
}

startVueApp()
