import { RouteRecordRaw } from 'vue-router'

const shippingMarketplace: RouteRecordRaw = {
	path: '/shipping-marketplace',
	name: 'Shipping-Marketplace',
	meta: { auth: true },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'Shipping Marketplace',
			component: () =>
				import('@/views/marketplace-shipping/marketplaceShipping.vue')
		},
	]
}
export default shippingMarketplace