import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const shipment: RouteRecordRaw = {
	path: '/shipment',
	name: 'Shipment',
	meta: { auth: true, title: 'ขนส่ง' },
	beforeEnter: () => {
		const { isChannelType } = useMiddleAccount()
		if (isChannelType.value) {
			return '/404'
		}
	},
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: 'rate',
			name: 'ShipmentRate',
			component: () => import('@/views/shipment/rate.vue')
		},
		
	]
}

export default shipment