import { RouteRecordRaw } from 'vue-router'

const application: RouteRecordRaw = {
	path: '/app-download',
	name: 'downloadApp',
	meta: { auth: false },
	component: () => import('@/views/application/download.vue'),
}

export default application
