import { defineStore } from 'pinia'
import { IPaymentList, TRANSACTION_STATUS } from './type'
import { fetchPaymentList, fetchPaymentQR } from '@/api/payment/payment.api'
import { Voucher } from '../voucher/types'
import { RESPONSE_DESC } from '@/constants/response'
import { GenerateQRPayload } from '@/api/subscription/subscription.type'
import { generateQRPayment } from '@/api/subscription/subscription.api'

export const paymentStore = defineStore('payment', {
	state: () => ({
		isLoadingList: false,
		paymentList: [] as any,
		pagination: {
			offset: 1,
			limit: 10,
			pages: 0,
			totals: 0,
		},
		// paymentDialog
		isShowPaymentDialog: false,
		transactionIdForPay: [] as string[],
		initialTransactionStatus: TRANSACTION_STATUS.PENDING,
		isLoadingQR: false,
		qr: {
			paymentAmount: 0,
			transactionId: null,
			tradeNo: null,
			qrRawData: null,
			qrExpireTime: null
		},
		isShowDialogStatus: false,
		isBlockBillPayment: false,
		dialogBillPayment: {
			isShow: false,
			isBtnClose: false
		},
		isShowBankDialogClose: false,
		isBlockBillPaymentOrder: true,
	}),
	actions: {
		async getPaymentList(shopId: string, status: TRANSACTION_STATUS) {
			try {
				this.isLoadingList = true

				const { data } = await fetchPaymentList({
					shopId,
					status,
					offset: this.pagination.offset,
					limit: this.pagination.limit
				})
				this.paymentList = data.data
				this.pagination.totals = data.pagination.totals
			} catch (error) {
				//
				console.log(error)
			} finally {
				this.isLoadingList = false
			}
		},
		async setPaymentDialog(billpaymentIds: string[], voucherId?: string) {
			this.initialTransactionStatus = TRANSACTION_STATUS.PENDING // set initialStatus
			this.isLoadingQR = true
			
			try {
				const { data } = await fetchPaymentQR(billpaymentIds, voucherId)
				if (data.resDesc === RESPONSE_DESC.VOUCHER_COMPLETED) {
					// Complete
					this.isShowDialogStatus = true
					this.initialTransactionStatus = TRANSACTION_STATUS.APPROVED
					return RESPONSE_DESC.VOUCHER_COMPLETED
				}

				this.transactionIdForPay = data.transactionId // for socket

				this.qr = {
					paymentAmount: data.paymentAmount,
					transactionId: data.transactionId,
					tradeNo: data.tradeNo,
					qrRawData: data.qrRawData,
					qrExpireTime: data.qrExpireTime
				}
				this.isShowPaymentDialog = true
			} catch (error: any) {
				if (error.response?.data?.resDesc === 'TRANSACTION_THIRD_PARTY_ERROR') {
					this.isShowBankDialogClose = true
				}
			} finally {
				this.isLoadingQR = false
			}
		},
		updateVoucherToPaymentList(payId: string, voucher: Voucher) {
			this.paymentList = this.paymentList.map((list: any) => {
				if (list._id === payId) {
					return {
						...list,
						voucher
					}
				}
				return list
			})
		},
		removeVoucherFromPaymentList(payId: string) {
			this.paymentList = this.paymentList.map((list: any) => {
				if (list._id === payId) {
					return {
						...list,
						voucher: null,
					}
				}
				return list
			})
		},
		async setSubscriptionDialog (payload: GenerateQRPayload) {
			try {
				const data = await generateQRPayment(payload)
				this.transactionIdForPay = data.transactionId as any // for socket
				this.qr = {
					paymentAmount: data.paymentAmount,
					transactionId: data.transactionId,
					tradeNo: data.tradeNo,
					qrRawData: data.qrRawData,
					qrExpireTime: data.qrExpireTime
				} as any
				this.isShowPaymentDialog = true
			} catch (error: any) {
				if (error.response?.data?.resDesc === 'TRANSACTION_THIRD_PARTY_ERROR') {
					this.isShowBankDialogClose = true
				}
			} finally {
				this.isLoadingQR = false
			}
		}
	}
})
