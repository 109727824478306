export const userCurrency = () => {
	
	const currencyMoney = (value: any) => {
		if(!value) return 0.00
		return new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(value)
	}

	const currencyMoneyDigits = (value: any) => {
		if(!value) return 0.00
		return new Intl.NumberFormat('th-TH', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
	}

	return {
		currencyMoney,
		currencyMoneyDigits
	}
}