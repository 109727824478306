import { useConfigStore } from '@/pinia/config/config.pinia'
import { RouteRecordRaw } from 'vue-router'

const marketplace: RouteRecordRaw = {
	path: '/marketplace',
	name: 'marketplace',
	meta: { auth: true, title: 'มาร์เก็ตเพลส' },
	component: () => import('@/layout/LayoutDefault.vue'),
	async beforeEnter() {
		const configStore = useConfigStore()
		await configStore.getRemoteGlobalConfig()
		if (!configStore.isShowChatMenu) {
			return { path: '/dashboard' } // redirect to dashboard
		}
	},
	children: [
		{
			path: '',
			name: 'listMarketplace',
			component: () => import('@/views/marketplace/index.vue'),
		},
	],
}

export default marketplace
