import { baseURL } from '@/config'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import authenticationRoute from './authentication'
import error from './error'
import { shopEditRoute, shopRoute, shopList } from './shop'
import product from './product'
import order from './order'
import dashboard from './dashboard'
import liveStream from './live-stream'
import shipping from './shipping'
import receipt from './receipt'
import user from './user'
import incoming from './incoming'
import report from './report'
import fill from './fill'
import booking from './booking'
import forDevelopment from './for-development'
import suspend from './suspend'
import payment from './payment'
import shipment from './shipment'
import facebookChat from './facebook-chat'
import topUp from './top-up'
import mentReply from './mentreply'
import reward from './reward'
import postCf from './post-cf'
import packageList from './package'
import calculator from './calculator'
import loading from './loading'
import marketplace from './marketplace'
import shippingMarketplace from './shopping-marketplace'
import application from './application'

const routes: Array<RouteRecordRaw> = [
	dashboard,
	authenticationRoute,
	// All Shop
	shopList,
	shopRoute,
	shopEditRoute,
	// // // // // // //
	product,
	order,
	liveStream,
	shipping,
	receipt,
	user,
	incoming,
	report,
	fill,
	booking,
	suspend,
	payment,
	shipment,
	facebookChat,
	topUp,
	mentReply,
	postCf,
	reward,
	packageList,
	calculator,
	loading,
	marketplace,
	shippingMarketplace,
	application,
	error, // Error Page must be the last in router list
]

// Development only Hack for check from baseURL
if (baseURL === 'https://api-dev.orderplus.me/') {
	// Push Route for development only
	routes.push(forDevelopment)
}

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
