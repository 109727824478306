import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'
import { decisionComponent } from '@/utils/routerHack'
import { useUserStore } from '@/pinia/user/user.pinia'

const report: RouteRecordRaw = {
	path: '/report',
	name: 'Report',
	meta: { auth: true, title: 'รายงาน' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const shopStore = useShopStore()
		shopStore.updateIsDialogInboxImage(false) // hide inbox dialog
	},
	children: [
		{
			path: '',
			name: 'ReportMain',
			meta: { title: 'พิมพ์รายงาน' },
			component: () => import('@/views/report/index.vue')
		},
		{
			path: 'shipping',
			name: 'ShippingReport',
			beforeEnter: () => {
				const { isChannelType } = useMiddleAccount()
				if (isChannelType.value) {
					return '/404'
				}
			},
			meta: { title: 'รายงานค่าขนส่ง' },
			component: () => import('@/views/report/shipping.vue')
		},
		{
			path: 'cod',
			name: 'ReportCOD',
			meta: { title: 'รายงาน COD' },
			beforeEnter: () => {
				const userStore = useUserStore()
				const { isDealerType } = useMiddleAccount()
				
				if (!userStore.isOwner && !userStore.isManager) {
					return '/404'
				}

				if (isDealerType.value) {
					return '/404'
				}
			},
			...decisionComponent({
				regular: import('@/views/report/cod/index.vue'),
				channel: import('@/views/report/cod/middleAccountCod.vue')
			})
		},
		{
			path: 'cod/:reconcileId',
			name: 'ReportCODDetail',
			meta: { title: 'รายงาน COD' },
			beforeEnter: () => {
				const userStore = useUserStore()
				const { isDealerType } = useMiddleAccount()

				if (!userStore.isOwner && !userStore.isManager) {
					return '/404'
				}
		
				if (isDealerType.value) {
					return '/404'
				}
			},
			...decisionComponent({
				regular: import('@/views/report/cod/_codId.vue'),
				channel: import('@/views/report/cod/_middleAccountCodId.vue')
			})
		},
		{
			path: 'cod_calendar',
			name: 'ReportCODCalendarDetail',
			meta: { title: 'รายงานปฏิทิน COD' },
			beforeEnter: () => {
				const userStore = useUserStore()
				const { isDealerType } = useMiddleAccount()

				if (!userStore.isOwner && !userStore.isManager) {
					return '/404'
				}
		
				if (isDealerType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/report/cod/_codCalendar.vue')
		},
		{
			path: 'social',
			name: 'ReportSocial',
			meta: { title: 'รายงานเพจ' },
			// component: () => import('@/views/report/social.vue')
			component: () => import('@/views/incoming.vue')
		},
		{
			path: 'customer',
			name: 'CustomerReport',
			meta: { title: 'รายงานลูกค้า' },
			beforeEnter: () => {
				const { isDealerType } = useMiddleAccount()
		
				if (isDealerType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/report/customer.vue')
		},
		{
			path: 'cost',
			name: 'CostReport',
			meta: { title: 'รายงานกำไรขาดทุน' },
			beforeEnter: () => {
				const { isDealerType } = useMiddleAccount()
		
				if (isDealerType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/report/cost.vue')
		},
		{
			path: 'commission',
			name: 'Comminsion',
			meta: { title: 'รายงาน คอมมิชชั่น'},
			beforeEnter: () => {
				const { isDealerType } = useMiddleAccount()
		
				if (isDealerType.value) {
					return '/404'
				}
			},
			component: () => import('@/views/report/commission.vue')
		},
	]
}

export default report