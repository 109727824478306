import { AxiosResponse } from 'axios'
import axios from '../instance'
import { CourierResponse } from './type'

export const getCourierList = async (): Promise<
	AxiosResponse<CourierResponse[]> | any
> => {
	try {
		const response = await axios.get('/courier')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getRemoteZone = async () => {
	try {
		const response = await axios.get('/remote-zone')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getTravelZone = async () => {
	try {
		const response = await axios.get('/travel-zone')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCourierIssueZone = async () => {
	try {
		const response = await axios.get('/address/issue-zone')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getAreasPickUpSPX = async () => {
	try {
		const response = await axios.get('/remote-zone/spx-areas')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

