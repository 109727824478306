import { useConfigStore } from '@/pinia/config/config.pinia'
import { useMiddleAccount } from '@/use/middleAccount'
import { RouteRecordRaw } from 'vue-router'

const mentReply: RouteRecordRaw = {
	path: '/page-comment',
	name: 'mentReplyMain',
	meta: { auth: true, title: 'ตั้งค่าตอบกลับโพสต์' },
	components: {
		default: () => import('@/layout/LayoutDefault.vue'),
	},
	beforeEnter: () => {
		const { isDealerType } = useMiddleAccount()

		if (isDealerType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'pageMentTableList',
			component: () => import('@/views/mentreply/index.vue'),
		},
		{
			path: ':pageId',
			name: 'mentReplyCreate',
			component: () => import('@/views/mentreply/_pageId.vue'),
		},
		{
			path: ':pageId/:mentId',
			name: 'mentReplyCreateOrEdit',
			component: () => import('@/views/mentreply/_createOrEdit.vue'),
		},
	],
}

export default mentReply
