/* eslint-disable indent */
import { defineStore } from 'pinia'
import { getAddressVersion } from '@/api/address/addressSystem'
import {
	getAreasPickUpSPX,
	getCourierIssueZone,
	getCourierList,
	getRemoteZone,
	getTravelZone,
} from '@/api/courier/courier.service'
import { IZipCodes } from '@/type/courier'
import {
	ISouthernPayload,
	IssueGetterData,
	RemoteZoneGetterData,
	State,
	TravelZoneGetterData,
} from '@/pinia/courier/type'
import { COURIER, getCourierId } from '@/constants/courier'
import { useMiddleAccount } from '@/use/middleAccount'
import { useUserStore } from '../user/user.pinia'
import { threeSouthernProvinceCode } from '@/constants/shop'

export const useCourierStore = defineStore('courier', {
	persist: true,
	state: (): State => ({
		version: null,
		remoteZoneCourier: [],
		areasPickUpSPX: [],
		couriers: [],
		// courierList: [],
		travelZoneCourier: [],
		issueZone: [],
	}),
	getters: {
		middleAccountCourierList: (state) => {
			const allowCourierName = [COURIER.JT, COURIER.FLASH, COURIER.EMS]

			return state.couriers.filter((courier) =>
				allowCourierName.includes(courier.name)
			)
		},
		courierList: (state) => {
			const { isChannelType } = useMiddleAccount()
			const { permission } = useUserStore()

			let couriers = [...state.couriers]
			if (isChannelType.value) {
				couriers = couriers.filter((courier) => {
					const isCourierApprove = permission.channelCourier.some(
						(channelCourier: any) =>
							channelCourier?.courier?._id === courier._id &&
							channelCourier.status === 'APPROVED'
					)

					return isCourierApprove
				})
			}
			return couriers
		},
		someCourierList: (state) => {
			const { isChannelType } = useMiddleAccount()
			const { permission } = useUserStore()

			let couriers = [...state.couriers]
			if (isChannelType.value) {
				couriers = couriers.filter((courier) => {
					const isCourierApprove = permission.channelCourier.some(
						(channelCourier: any) =>
							channelCourier?.courier?._id === courier._id &&
							channelCourier.status === 'APPROVED'
					)

					return isCourierApprove
				})
			}
			return couriers.filter((courier) => courier.name === COURIER.FLASH || courier.name === COURIER.FLASH_BULKY || courier.name === COURIER.EMS || courier.name === COURIER.NINJA_VAN || courier.name === COURIER.KERRY || courier.name === COURIER.SPX)
		},
		isProvinceCodeInSouthernProvinces: 
			(state) => 
			(southernPayload: ISouthernPayload) => {
				// provinceCode of 3 จังหวัดชายแดดภาคใต้
				const isProvinceCodeInSouthernProvinces = (provinceCode: number): boolean => threeSouthernProvinceCode.includes(provinceCode)
				const isShipmentSouthern = isProvinceCodeInSouthernProvinces(southernPayload.shipment.provinceCode as number)
				const isOriginSouthern = isProvinceCodeInSouthernProvinces(southernPayload.origin?.provinceCode as number)

				const findCourierById = state.couriers.find(
					(each: any) => each._id?.toString() === southernPayload.courierId?.toString()
				)
				const isFlash =  findCourierById?.name === COURIER.FLASH || findCourierById?.name === COURIER.FLASH_BULKY
				if (isFlash && isShipmentSouthern && isOriginSouthern) {
					return true
				}
				return false
		},
		findRemoteZone:
			(state) =>
			(zipCode: IZipCodes): RemoteZoneGetterData | undefined => {
				const findRemoteZoneFromCourierId: any =
					state.remoteZoneCourier.find((each: any) => {
						return each.courier === zipCode.courierId
					})
				if (!findRemoteZoneFromCourierId) return undefined

				const isRemoteZone = findRemoteZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)
				const addressRemote: any =
					findRemoteZoneFromCourierId.items.find(
						(code: IZipCodes) => {
							return (
								code.provinceCode === zipCode.provinceCode &&
								code.districtCode === zipCode.districtCode &&
								code.subDistrictCode ===
									zipCode.subDistrictCode &&
								code.zipCode === zipCode.zipCode
							)
						}
					)
				if (!isRemoteZone) return undefined

				return {
					remoteFee: findRemoteZoneFromCourierId.remoteFee,
					courier: findRemoteZoneFromCourierId.courier,
					addressRemote: addressRemote,
				}
			},
		findCourierByName() {
			return (courierName: string) => {
				const findCourierByName = this.courierList.find(
					(each: any) => each.name === courierName
				)
				return findCourierByName
			}
		},
		findCourierById() {
			return (courierId: string) => {
				const findCourierById = this.couriers.find(
					(each: any) => each._id?.toString() === courierId?.toString()
				)
				return findCourierById
			}
		},
		findTravelZone:
			(state) =>
			(zipCode: IZipCodes): TravelZoneGetterData | undefined => {
				const findTravelZoneFromCourierId: any =
					state.travelZoneCourier.find((each: any) => {
						return each.courier === zipCode.courierId
					})

				if (!findTravelZoneFromCourierId) return undefined

				const isTravelZone = findTravelZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)

				const addressRemote: any =
					findTravelZoneFromCourierId.items.find(
						(code: IZipCodes) => {
							return (
								code.provinceCode === zipCode.provinceCode &&
								code.districtCode === zipCode.districtCode &&
								code.subDistrictCode ===
									zipCode.subDistrictCode &&
								code.zipCode === zipCode.zipCode
							)
						}
					)
				if (!isTravelZone) return undefined

				return {
					travelFee: findTravelZoneFromCourierId.travelFee,
					courier: findTravelZoneFromCourierId.courier,
					addressRemote: addressRemote,
				}
			},
		findIssueZone:
			(state) =>
			(zipCode: IZipCodes): IssueGetterData | undefined => {
				const findIssueZoneFromCourierId: any = state.issueZone.find(
					(each: any) => {
						return each.courier === zipCode.courierId
					}
				)

				if (!findIssueZoneFromCourierId) return undefined

				const isIssueZone = findIssueZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)

				const addressRemote: any =
					findIssueZoneFromCourierId.items.find((code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					})

				if (!isIssueZone) return undefined

				const findCourier = state.couriers.find(
					(each) => each._id === findIssueZoneFromCourierId.courier
				)

				return {
					courier: findCourier,
					addressRemote: addressRemote,
				}
			},
			findAreasPickUpSPX:
			(state) =>
				(courierId: string,subDistrictCode: string | number,zipcode: string | number) => {
				let status = true
				//Shopee
				const spxID = getCourierId(COURIER.SPX)
				if(state.areasPickUpSPX && courierId == spxID){
					const findAreasPickUpSPXFromZipCodeId: any = state.areasPickUpSPX.find(
						(each: any) => {
							return each.subDistrictCode === subDistrictCode && each.pickUp == false && zipcode === each.zipCode
						}
					)
					if(findAreasPickUpSPXFromZipCodeId){
						status = false
					}
				}
				return status
			},
	},
	actions: {
		async getRemoteZoneAndTravelZoneCourier() {
			try {
				const { data: addressVersion } = await getAddressVersion()

				// no need cache need to refetch
				const issueZone = await getCourierIssueZone()
				this.issueZone = issueZone

				if (!this.version || this.version !== addressVersion) {
					const [remoteZone, travelZone, PickUpSPX] = await Promise.all([
						getRemoteZone(),
						getTravelZone(),
						getAreasPickUpSPX(),
					])

					this.remoteZoneCourier = remoteZone
					this.areasPickUpSPX = PickUpSPX
					this.travelZoneCourier = travelZone
					this.version = addressVersion
				}
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getCourierList() {
			try {
				const courier = await getCourierList()
				// hardcode temp remove JT from courier
				// this.couriers = courier.filter((each: any) => each.name !== COURIER.LAZADA_BY_SELLER && each.name !== COURIER.JT)
				this.couriers = courier
			} catch (error) {
				return Promise.reject(error)
			}
		},

		mapCourierWithOrder(orders: {data:{order:any,courier:string}[]}) {
			if (!orders.data || orders.data.length === 0) {
				return orders
			}
		
			const updatedData = orders.data.map((order: { courier: string | any }) => {
				if (order.courier) {
					if (!order.courier?._id) {
						const foundCourier = this.courierList.find(courier => courier._id?.toString() === order.courier?.toString())
						return { ...order, courier: foundCourier }
					}
					return order
				}
				return order
			})
			return { ...orders, data: updatedData }
		}
	},
})
