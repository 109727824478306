import { SHOP_CREDIT_TYPE } from '@/constants/credit'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { ComputedRef, computed } from 'vue'

interface IUseMiddleAccount {
	isChannelType: ComputedRef<boolean>,
	isSubscriptionType: ComputedRef<boolean>,
	isDealerType: ComputedRef<boolean>,
	isEnableMarketplace: ComputedRef<boolean>,
}

export const useMiddleAccount = (): IUseMiddleAccount  => {
	const shopStore = useShopStore()
	const userStore = useUserStore()

	const isChannelType = computed<boolean>(() => {
		return userStore.permission?.shopCreditType === SHOP_CREDIT_TYPE.CHANNEL
	})

	const isDealerType = computed<boolean>(() => {
		return userStore?.permission?.settingConfig?.isDealer || false
	})

	const isEnableMarketplace = computed<boolean>(() => {
		return userStore?.permission?.settingConfig?.enableMarketplace || false
	})

	const isSubscriptionType = computed<boolean>(() => {
		const isSubscription = userStore.permission?.subscriptions?.some((each: any) => each.enable)
		return isSubscription
	})

	return {
		isChannelType,
		isSubscriptionType,
		isDealerType,
		isEnableMarketplace
	}
}