<script lang="ts" setup>
</script>
<template>
	<div class="container">
		<img
			class="image"
			:src="require('@/assets/new-order-plus-with-word.png')" />
	</div>
</template>


<style lang="scss" scoped>
.image {
  width: 18rem;
  height: auto;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $bz-primary;
	height: 100%;
	width: 100%;
	background-image: url('~@/assets/images/background-core.png');
	background-repeat: no-repeat;
	background-size: 56rem 35rem;
}
</style>