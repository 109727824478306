import { RouteRecordRaw } from 'vue-router'
import { useCredit } from '@/use/credits/useCredit'
import { useMiddleAccount } from '@/use/middleAccount'

const payment: RouteRecordRaw = {
	path: '/payment',
	name: 'payment',
	meta: { auth: true, title: 'ชำระเงิน' },
	component: () => import('@/layout/LayoutDefault.vue'),
	beforeEnter: () => {
		const { isTopUpType } = useCredit()
		const { isChannelType } = useMiddleAccount()
		
		if (isTopUpType.value && isChannelType.value) {
			return '/404'
		}
	},
	children: [
		{
			path: '',
			name: 'paymentList',
			component: () =>
				import('@/views/payment/index.vue')
		},
		{
			path: 'order-list',
			name: 'paymentOrderList',
			component: () => import('@/views/payment/order-list.vue')
		},
		{
			path: 'order-list-oppose',
			name: 'paymentOrderListOppose',
			component: () => import('@/views/payment/order-list-oppose.vue')
		}
	]
}

export default payment