import { RouteRecordRaw } from 'vue-router'

const shipping: RouteRecordRaw = {
	path: '/shipping',
	name: 'shipping',
	meta: { auth: true },
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'Shipping',
			component: () =>
				import('@/views/shipping/Shipping.vue')
		},
		{
			path: 'one',
			name: 'ShippingOne',
			component: () =>
				import('@/components/shipping/ShippingOne.vue')
		},
		{
			path: 'four',
			name: 'ShippingFour',
			component: () =>
				import('@/components/shipping/ShippingFour.vue')
		},
		{
			path: 'six',
			name: 'ShippingSix',
			component: () =>
				import('@/components/shipping/ShippingSix.vue')
		},
		{
			path: 'sticker',
			name: 'ShippingSticker',
			component: () =>
				import('@/components/shipping/ShippingSticker4x4.vue')
		},
		{
			path: 'sticker4x6',
			name: 'ShippingStickerSix',
			component: () =>
				import('@/components/shipping/ShippingSticker4x6.vue')
		},
		{
			path: 'paperang',
			name: 'ShippingPaperang',
			component: () =>
				import('@/components/shipping/ShippingPaperang.vue')
		},
	]
}
export default shipping