import { App } from 'vue'
import {
	Plus,
	CircleCheckFilled,
	Refresh,
	TrendCharts,
	Box,
	ShoppingCart,
	VideoCamera,
	Expand,
	Tickets,
	UploadFilled,
	CircleCloseFilled,
	Van,
	Picture,
	CameraFilled,
	Wallet,
	Flag,
	Delete,
	Edit,
	Setting,
	InfoFilled,
	Close,
	CloseBold,
	Check,
	Select,
	ArrowLeft,
	DocumentCopy,
	Right,
	Back,
	ArrowDown,
	Document,
	Upload,
	Money,
	Finished,
	SuccessFilled,
	WarningFilled,
	House,
	MoreFilled,
	Eleme,
	ArrowLeftBold,
	ChatDotRound,
	Operation,
	ChatLineSquare,
	User,
	Tools,
	WalletFilled,
	Coin,
	View,
	Phone,
	UserFilled,
	Position,
	ChatLineRound,
	PhoneFilled,
	Histogram,
	Menu,
	Goods,
	Warning,
	Sort,
	Filter,
	PieChart,
	BellFilled,
	Connection,
	CopyDocument
} from '@element-plus/icons'

const initialElementPlusIcon = (app: App<Element>): void => {
	// GLobal Icon
	const icons = [
		Plus,
		CircleCheckFilled,
		Refresh,
		TrendCharts,
		Box,
		ShoppingCart,
		VideoCamera,
		Expand,
		Tickets,
		UploadFilled,
		CircleCloseFilled,
		Van,
		Picture,
		CameraFilled,
		Wallet,
		Flag,
		Delete,
		Edit,
		Setting,
		InfoFilled,
		Close,
		CloseBold,
		Check,
		Select,
		ArrowLeft,
		DocumentCopy,
		Right,
		Back,
		ArrowDown,
		Document,
		Upload,
		Money,
		Finished,
		SuccessFilled,
		WarningFilled,
		House,
		MoreFilled,
		Eleme,
		ArrowLeftBold,
		ChatDotRound,
		Operation,
		ChatLineSquare,
		User,
		Tools,
		WalletFilled,
		Money,
		Coin,
		View,
		Phone,
		UserFilled,
		Van,
		Position,
		ChatLineRound,
		PhoneFilled,
		Histogram,
		Menu,
		Goods,
		Warning,
		Sort,
		Filter,
		PieChart,
		Histogram,
		BellFilled,
		Connection,
		CopyDocument
	]

	for (const icon of icons) {
		app.component(icon.name as string, icon)
	}
}

export default initialElementPlusIcon
